<template>
  <div>
    <div>
      <div class="project-search">
        <div class="flex search-text align-center">
          <div>
            <span>名称：</span>
            <input v-model="pageInfo.name" placeholder="名称" class="searchInput"/>
          </div>
          <button class="searchBtn" @click="search">搜索</button>
          <button class="resetBtn" @click="reset(null)">重置</button>
        </div>
      </div>
      <div class="project-table" style="margin-top: 20px">
        <div class="flex align-center justify-between paddingBTM20">
          <div class="fontSize20gay">标准化接口</div>
          <div>
            <button class="addBtn" @click="showEdit()"> + 新增</button>
          </div>
        </div>
        <el-table :data="tableData" border row-key="id"
                  :header-cell-style="{'text-align':'center','color':'#999999','padding':'16px 0','background-color':'#EBEBEB'}"
                  :cell-style="{'text-align':'center'}">
          <el-table-column prop="id" label="ID"/>
          <el-table-column prop="name" label="名称" show-overflow-tooltip/>
          <el-table-column prop="ip" label="IP地址" show-overflow-tooltip/>
          <el-table-column prop="startTime" label="开始时间" show-overflow-tooltip/>
          <el-table-column prop="endTime" label="结束时间" show-overflow-tooltip/>
          <el-table-column prop="time" label="创建时间"/>
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button link type="primary" style="color: #3769B9" @click="showEdit(scope.row)">编辑</el-button>
            </template>
          </el-table-column>
        </el-table>
        <div class="flex justify-center paddingTD20">
          <!--   分页     -->
          <el-pagination
              :currentPage="pageInfo.page"
              :page-size="pageInfo.limit"
              :page-sizes="[10, 20, 50, 100]"
              :small="true"
              :disabled="false"
              :background="true"
              layout="total, sizes, prev, pager, next, jumper"
              :total="pageInfo.total"
              @size-change="handleSizeChange"
              @current-change="handleCurrentChange"
          />
        </div>
      </div>
    </div>
    <!--弹框-->
    <el-dialog :title="pageForm.id > 0 ? '编辑': '新增'" v-model="editDialogVisible" width="35%"
               class="info-div600" style="padding: 24px;text-align: left">
      <el-form label-width="80px" :model="pageForm" :rules="rules" ref="editFormRef">
        <el-form-item label="名称" prop="name">
          <el-input v-model="pageForm.name" autocomplete="off" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="IP地址" prop="ip">
          <el-input v-model="pageForm.ip" autocomplete="off" placeholder="请输入"/>
        </el-form-item>
        <el-form-item label="有效时间" prop="times">
          <el-date-picker v-model="pageForm.times"
                          type="datetimerange"
                          is-range
                          range-separator="至"
                          placeholder="请选择"
                          start-placeholder="开始时间"
                          end-placeholder="结束时间"
                          value-format="YYYY-MM-DD HH:mm:ss"
                          style="width: 100%;">

          </el-date-picker>
        </el-form-item>
        <div class="dialog-footer marginTop30px text-right">
          <button class="qxBtn" type="button" @click="editDialogVisible = false">取消</button>
          <button class="subBtn marginLeft15" type="button" @click="submit('editFormRef')">保存</button>
        </div>
      </el-form>
    </el-dialog>
  </div>
</template>

<script>
import {interfacePage, saveInterface} from "@/api/monitor";

export default {
  data() {
    return {
      tableData: [],
      pageInfo: {
        limit: 10,
        name: '',
        page: 1,
        total: 0,
      },
      // 表单
      editDialogVisible: false,
      pageForm: {
        id: '',
        name: '',
        startTime: '',
        endTime: '',
        ip: '',
        times: [],
      },
      rules: {
        name: [{required: true, message: '请输入名称', trigger: 'blur'}],
        startTime: [{required: true, message: '请选择有效时间', trigger: 'blur'}],
        endTime: [{required: true, message: '请选择有效时间', trigger: 'blur'}],
        times: [{required: true, type: 'array', message: '请选择有效时间', trigger: 'blur'}],
        ip: [{required: true, message: '请输入IP地址', trigger: 'blur'}],
      },
    }
  },
  mounted() {
    this.getList();
  },
  methods: {
    reset() {
      this.pageInfo.name = ''
    },
    search() {
      this.pageInfo.limit = 10;
      this.pageInfo.page = 1;
      this.getList();
    },
    getList() {
      interfacePage(this.pageInfo).then(res => {
        this.pageInfo.total = res?.data?.total || 0;
        this.tableData = res?.data?.records || []
      })
    },
    showEdit(item = null) {
      this.pageForm = {
        id: item?.id || '',
        name: item?.name || '',
        ip: item?.ip || '',
        startTime: item?.startTime || '',
        endTime: item?.endTime || '',
        times: [item?.startTime || '', item?.endTime || ''],
      }
      this.editDialogVisible = true
    },
    submit(formName) {
      this.pageForm.startTime = this.pageForm.times[0]
      this.pageForm.endTime = this.pageForm.times[1]
      this.$refs[formName].validate((valid) => {
        if (valid) {
          saveInterface(this.pageForm.id > 0 ? 'put' : 'post', this.pageForm).then(res => {
            if (!res.success) {
              this.$message.error(res.message || '保存失败')
              return
            }
            this.$message.success('保存成功')
            this.editDialogVisible = false
            this.getList()
          })
        }
      })
    },
    // 分页
    handleSizeChange(e) {
      this.pageInfo.limit = e;
      this.pageInfo.page = 1;
      this.getList();
    },
    handleCurrentChange(e) {
      this.pageInfo.page = e;
      this.getList();
    },
  }
}
</script>

<style lang="scss" scoped>
</style>